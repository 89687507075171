
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexPGJG9cCvw_45clsE2m5YiuapBUo8UfSm5NBHY0E9JtBxEMeta } from "/home/black/project/me/danny.portofolio/pages/index.vue?macro=true";
import { default as homeS6JVr81TVvFO_ba7WfqvormGTKYOx_454S1sLc4B_45szKUMeta } from "/home/black/project/me/danny.portofolio/pages/home/home.vue?macro=true";
import { default as workr3Q4fOnqz98Y2pN9zUXsk74lb3jZeke2DpN8Tfb_m2kMeta } from "/home/black/project/me/danny.portofolio/pages/about/work.vue?macro=true";
import { default as indexr_shRhj32mPlSTLqiXPGf81jUDxVo4b84G9yJy2aIn4Meta } from "/home/black/project/me/danny.portofolio/pages/about/index.vue?macro=true";
import { default as admin2wcAbJWkhNeKzGM30hVFghUS43TH2iHJM5m700NDRzkMeta } from "/home/black/project/me/danny.portofolio/pages/admin/admin.vue?macro=true";
import { default as webyuy078VWw2E08us5flNbMm6oayz_KujoX_45HHPySkIrYMeta } from "/home/black/project/me/danny.portofolio/pages/project/web.vue?macro=true";
import { default as _91id_93_45PTEwR3siTEe7vZXP59PXewVogF4oWrHtJgoAAFJTyYMeta } from "/home/black/project/me/danny.portofolio/pages/project/[id].vue?macro=true";
import { default as ideamDDesHw_RLUHYTZHvJTZAbJ9TXuUw1Q2QGAmaluJrysMeta } from "/home/black/project/me/danny.portofolio/pages/project/idea.vue?macro=true";
import { default as toolbox7XicYkHXDfxgCgX3G5V89JFL6wCMC4X_VojNV9jUw5QMeta } from "/home/black/project/me/danny.portofolio/pages/about/toolbox.vue?macro=true";
import { default as indexRK8Yblc5wDnC_TGbc4E2CtvD3xFzuWdbsaOyHi_LYbQMeta } from "/home/black/project/me/danny.portofolio/pages/project/index.vue?macro=true";
import { default as personalfZU_b3OWQZCcJUlhuWV7_HVm1SINHuGnQQtvbUmY25UMeta } from "/home/black/project/me/danny.portofolio/pages/about/personal.vue?macro=true";
import { default as registeri24L6gSDCC_yeDDj2iDxjBwpZwOSiEXHzkCWJ6DtVpsMeta } from "/home/black/project/me/danny.portofolio/pages/admin/register.vue?macro=true";
import { default as index338nOB_vnshFvm9H1wU7JzL7Ujg4LI9x7bWkaiLobwAMeta } from "/home/black/project/me/danny.portofolio/pages/articles/index.vue?macro=true";
import { default as commandBbOXmUrCZxkUuSyQ9E2c_jR78wiR9NN0o8BkwI1JubcMeta } from "/home/black/project/me/danny.portofolio/pages/project/command.vue?macro=true";
import { default as securitysF4CEu_fhS0sO5I2hcCYEmKOlL6bKtLU27n5fL1xz9QMeta } from "/home/black/project/me/danny.portofolio/pages/project/security.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/black/project/me/danny.portofolio/pages/index.vue")
  },
  {
    name: "home-home",
    path: "/home/home",
    component: () => import("/home/black/project/me/danny.portofolio/pages/home/home.vue")
  },
  {
    name: "about-work",
    path: "/about/work",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/work.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/index.vue")
  },
  {
    name: "admin-admin",
    path: "/admin/admin",
    component: () => import("/home/black/project/me/danny.portofolio/pages/admin/admin.vue")
  },
  {
    name: "project-web",
    path: "/project/web",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/web.vue")
  },
  {
    name: "project-id",
    path: "/project/:id()",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/[id].vue")
  },
  {
    name: "project-idea",
    path: "/project/idea",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/idea.vue")
  },
  {
    name: "about-toolbox",
    path: "/about/toolbox",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/toolbox.vue")
  },
  {
    name: "project",
    path: "/project",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/index.vue")
  },
  {
    name: "about-personal",
    path: "/about/personal",
    component: () => import("/home/black/project/me/danny.portofolio/pages/about/personal.vue")
  },
  {
    name: "admin-register",
    path: "/admin/register",
    component: () => import("/home/black/project/me/danny.portofolio/pages/admin/register.vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/home/black/project/me/danny.portofolio/pages/articles/index.vue")
  },
  {
    name: "project-command",
    path: "/project/command",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/command.vue")
  },
  {
    name: "project-security",
    path: "/project/security",
    component: () => import("/home/black/project/me/danny.portofolio/pages/project/security.vue")
  }
]